@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&family=Montserrat:wght@400;600&family=Outfit:wght@400;500&display=swap");
.section-main {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  max-width: 95em;
  width: 100%;
  font-family: "Inter" !important;
  font-size: 1vw;
  margin: auto;
  margin-top: 1.875em;
  // padding-left: 2.4305555556em;
  // padding-right: 2.4305555556em;
}
.main-block-new {
  width: 100%;
  // padding: 0 6em 0 6.3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5.691vh;
}
.top-btn-wrapper {
  display: flex;
  width: 100%;
  padding: 2.2222222222em 2.7777777778em;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.7361111111em;
  border-radius: 1.7361111111em;
  background: #fff;
  -webkit-border-radius: 1.7361111111em;
  -moz-border-radius: 1.7361111111em;
  -ms-border-radius: 1.7361111111em;
  -o-border-radius: 1.7361111111em;
}
.sub-title-32 {
  color: #000;
  font-size: 2.2222222222em;
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 700;
  line-height: 1.28125; /* 41.6px */
}
.back-btn-wrapper {
  display: flex;
  align-items: center;
  gap: 0.8333333333em;
}
a {
  text-decoration: none;
}
.sub-head-20 {
  background: transparent;
  padding: 1.35vh 0vw 1.35vh 0.65vw;
  color: #000;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #ffd803;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  align-self: center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.top-bottom-block-new {
  display: flex;
  align-items: flex-start;
}

.flex-container {
  display: flex;
  align-items: stretch;
  width: 100%;
  gap: 1.7578125vw;
  background: transparent;
}
.ml-10 {
  margin-left: 0.4em;
  background: transparent;
}
.left-panel{
  max-width: 17.12vw;
}
.panel {
  padding-top: 3.11vh;
  padding-bottom: 3.11vh;
  background-color: #FFF4D9;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.35vh;
}
.box-optimization-wrapper{
  background: transparent;
  padding-left: 0.65vw;
  overflow: auto;
  max-height: 45vh;
}
.width-351 {
  width: 100%;
  margin-left: auto;
  max-width: 24.375em;
  max-height: 30em;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.item-box-empty-text{
  background: transparent;
}
.scroll-bar {
  margin-right: 0.5em;
  padding-right: 0.5em;
  background: transparent;
}
/*width*/
// .scroll-bar::-webkit-scrollbar {
//   width: 0.5555555556em;
// }

/*thumb*/
// .scroll-bar::-webkit-scrollbar-thumb {
//   background: #7a7a7a;
//   border-radius: 0.4em;
//   -webkit-border-radius: 0.4em;
//   -moz-border-radius: 0.4em;
//   -ms-border-radius: 0.4em;
//   -o-border-radius: 0.4em;
// }
// // .top-sec-new {
// //   // max-width: 27.60vw;
// //   // min-width: 20vw;
// // }
.field-sec {
  width: 100%;
  max-width: 36.5234375vw;
  min-width: 16vw;
}
::-webkit-scrollbar {
  width: 0.325vw !important;
}

::-webkit-scrollbar-thumb {
  background: #FFBE20 !important;
}

::-webkit-scrollbar-track {
  border-radius: 50px;
  background: white;
}
.top-sec .item-details {
  margin-bottom: 1em;
}

.flex-row-head-wrapper {
  width: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  padding: 1.35vh 1.88vw 1.35vh 1.88vw;
  justify-content: space-between;
}

.text-24 {
  background-color: transparent;
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.select-all-field{
  cursor: pointer;
  background: transparent;
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  }
.check-box-btn {
  width: 100%;
  height: 100%;
  max-width: 3.11vh;
  max-height: 1.497vw;
}


.is-selected .sub-head-20 {
  width: 100%;
  border-radius: 4px;
  background: #FFAB69;
}


.weight-400 {
  font-weight: 400;
}
.MuiFormControlLabel-root{
  background: transparent;
  margin: 0px !important;
  padding: 0px !important;
}
.separator{
  width: 0.0651vw;
  height: 2.032vh;
  opacity: 0.2;
  background: var(--NEW-BLACK, #222);
}
.collection-item,
.item-details {
  display: flex;
  align-items: center;
  gap: 0.52vw;
  background: transparent;
  padding-left: 1.30vw;
}
.item-wrapper{
  display: flex;
  align-items: center;
  background: transparent;
  gap: 0.65vw;
  padding-left: 1.30vw;
}

.item-details {
  align-items: flex-start;
}
.item-details .PrivateSwitchBase-root-1 {
  padding: 0;
  margin-right: 0em;
}
.field-details-wrapper {
  width: 100%;
  max-width: 37.2222222222em;
  display: flex;
  flex-direction: column;
  gap: 3.3333333333em;
  max-height: 34em;
  overflow: auto;
  padding-left: 1em;
}

.min-height-30 {
  min-height: 30em;
}

.min-height-45 {
  min-height: 45em;
}
.item-box-wrapper{
  display: flex;
  gap: 1.35vh;
  flex-direction: column;
  background: transparent;
  overflow: auto;
  max-height: 45vh;
}
.checkbox-wrapper{
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  background: transparent;
  gap: 1.302vw;
}
.field-details {
  gap: 1.95vw;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: stretch;
  background: transparent;
}
.remove-padding{
  padding: 0vh 0vw;
}
.PrivateSwitchBase-root-1{
  padding: 0% !important;
}
// .PrivateSwitchBase-root-9{
//   padding: 0% !important;
// }
.field-details .PrivateSwitchBase-root-1 {
  padding: 0;
  margin-right: 0em;
}
.field-details .PrivateSwitchBase-root-1 {
  padding: 0;
  margin-right: 0em;
}
.two-row-flex {
  display: flex;
  flex-direction: column;
  gap: 0.813vh;
  background-color: transparent;
}

.field-type {
  width: fit-content;
  background: transparent;
}
.image-icon-result{
  display: flex;
  align-items: center;
  gap: 0.22vw;
  padding: 0.54vh 0.325vw 0.54vh 0.325vw;
  background: transparent;
  border-radius: 2.706px;
  opacity: 0.7;
}
.image-icon {
  display: flex;
  align-items: center;
  gap: 0.22vw;
  padding: 0.54vh 0.325vw 0.54vh 0.325vw;
  background: transparent;
  border-radius: 2.706px;
  opacity: 0.7;
  background: #FFAB69;
}

.icon-16 {
  width: 1.072vw;
  height: 2.23vh;
  background: transparent;
}
.image-with-name {
  display: flex;
  padding: 0.813vh 0.390625vw;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  border: 1px solid #000;
  background: var(--Color-Neutral-white, #FFF);

}

.field-image {
  width: 7.8125vw;
  border-radius: 6px;
  background: transparent;
}
.fields-wrapper{
  display: flex;
  flex-direction: column;
  gap: 5.420vh;
  background: transparent;
  overflow-y: auto;
  max-height: 45vh;
  max-width: 35.8234375vw;
  overflow-x: hidden;
  width: 100%;
}
.field-flex {
  display: flex;
  flex-direction: column;
  gap: 2.71vh;
  background: transparent;
  padding-left: 1.888vw;
  padding-right: 1.888vw;
}

.image-name {
  display: flex;
  padding: 1.3550vh 3.2552vw;
  align-items: center;
  align-self: stretch;
  color: #000;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: transparent;
}

.rich-text-field {
  display: flex;
  flex-direction: column;
  padding: 2.032vh 0.97656vw;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid #1E2025;
  background: var(--Color-Neutral-white, #FFF);
}
.rich-text-field p{
  background: transparent;
}
.rich-text-field h2{
  background: transparent;
}
.rich-text-field figure{
  background: transparent;
}

.optimised-by {
  padding: 0.3333333333em 0.6666666667em;
  background: #272343;
  color: #fff;
  font-family: "Inter" !important;
  font-size: 1.0416666667em;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
  border-radius: 1.9333333333em;
  -webkit-border-radius: 1.9333333333em;
  -moz-border-radius: 1.9333333333em;
  -ms-border-radius: 1.9333333333em;
  -o-border-radius: 1.9333333333em;
  -webkit-border-radius: 1.9333333333em;
}

.result-container {
  display: flex;
  align-items: flex-start;
  gap: 1.7578125vw;
  width: 100%;
}
.result-details-heading{
  padding: 1.0785vh 0;
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Public Sans";
  font-size: 19.097px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: transparent;
}
.result-left-block {
  display: flex;
  flex-direction: column;
  padding: 2.4796vh 1.502vw 2.4796vh 1.502vw;
  align-items: flex-start;
  gap: 1.1165vh;
  width: 100%;
  max-width: 36.31vw;
  border-radius: 11.936px;
  background: #FFF4D9;
  max-height: 67.65vh;
  overflow: auto;
  -webkit-border-radius: 11.936px;
  -moz-border-radius: 11.936px;
  -ms-border-radius: 11.936px;
  -o-border-radius: 11.936px;
}

.btn-wrapper {
  align-self: center;
}

.btn-wrapper > p {
  margin-left: 1em;
}

.btn-wrapper .back-button {
  margin-bottom: 0.5em;
}

.full-width-for-result {
  max-width: none;
  width: 100%;
  border-right: 0;
}
.results-array-wrapper{
  background: transparent;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 18px;
  width: 100%;
}
.result-wrapper {
  background: transparent;
  display: flex;
  width: 100%;
  max-width: 15.26vw;
  // max-width: 31.9444444444em;
  padding: 2.696vh 1.295vw;
  flex-direction: column;
  align-items: flex-end;
  gap: 1.3888888889em;
  border-radius: 15.914px;
  border: 1px solid #d9d9d9;
  background: #fff;
  -webkit-border-radius: 15.914px;
  -moz-border-radius: 15.914px;
  -ms-border-radius: 15.914px;
  -o-border-radius: 15.914px;
}
.result-subheading{
  margin-top: 4.065vh;
  color: rgba(0, 0, 0, 0.80);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}
// .p-btm-45 {
//   padding-bottom: 3.125em;
// }
.text-18 {
  color: #000;
  font-family: "Inter" !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  background: transparent;
}
.width-100 {
  width: 100%;
  background: transparent;
}

.field-info-wrapper {
  display: flex;
  align-items: center;
}

.content-info-wrap {
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.4em;
  width: 100%;
}
.tags-wrapper {
  display: flex;
  gap: 0.5em;
  flex-direction: column;
  align-items: flex-start;
  background: transparent;
}
.tag {
  color: #272343;
  font-family: "Inter" !important;
  font-size: 0.6em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0.5em;
  text-align: center;
  background-color: #ffd803;
  border-radius: 0.1em;
  -webkit-border-radius: 0.5em;
  margin: 0 0.4em 0.2em 0;
}
.result-field-img {
  width: auto;
  height: 100%;
  background: transparent;
  // max-width: 9.0277777778em;
  // max-height: 8.3333333333em;
}

.status-wrapper {
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.0416666667em;
  width: 100%;
  max-width: 11.1111111111em;
}

.result-state-wrapper {
  background: transparent;
  display: flex;
  align-items: center;
  gap: 0.8333333333em;
}
.text-13 {
  background: transparent;
  color: #000;
  font-family: "Inter" !important;
  font-size: 10.344px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.status-tag {
  padding: 0.4615384615em 0.7692307692em;
  gap: 0.7692307692em;
  text-align: center;
  border-radius: 3.4615384615em;
  background: #272343;
  color: #fff;
  text-transform: capitalize;
  -webkit-border-radius: 3.4615384615em;
  -moz-border-radius: 3.4615384615em;
  -ms-border-radius: 3.4615384615em;
  -o-border-radius: 3.4615384615em;
}

.result-progress {
  background: transparent;
  width: 19.097px;
  height: 19.097px;
}
.optimized-info-wrapper {
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.0416666667em;
}

.result-field-multi-img {
  display: flex;
  width: 20em;
  flex-wrap: wrap;
}

.result-field-multi-img > img {
  width: 3em;
}

.ml-2 {
  margin-left: 0.2em;
}
.text-14 {
  color: #000;
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: transparent;
}

.result-right-block {
  margin-top: 8.672vh;
  width: 100%;
  max-width: 42.8385vw;
  padding: 5.28vh 6.054vw 5.337vh 5.989vw;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 12.171px;
  border: 1.623px solid #000;
  background: var(--Light-Yellow, #FFF1CF);
  box-shadow: 6.491px 6.491px 0px 0px #000;
}

.result-right-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4.7425vh;
  background: transparent;
}

.tag-28 {
  padding: 0.948vh 1.302vw;
  border-radius: 47px;
  background: #1E2025;
  flex-direction: column;
  align-items: flex-start;
  color: var(--Color-Neutral-white, #FFF);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.stats-wrapper{
  display: flex;
  width: 100%;
  background: transparent;
  justify-content: center;
  align-items: flex-start;
  gap: 2.27864vw;
}
.stats-block{
  width: 100%;
  height: auto;
  display: flex;
  padding: 1.07018vh 0.6169vw;
  flex-direction: column;
  align-items: center;
  border-radius: 5.528px;
  gap: 0.749vh;
  border: 1px solid #1E2025;
  background: var(--Color-Neutral-white, #FFF);
  box-shadow: 3px 3px 0px 0px #000;
  -webkit-border-radius: 5.528px;
  -moz-border-radius: 5.528px;
  -ms-border-radius: 5.528px;
  -o-border-radius: 5.528px;
}

.font-28{
  background: transparent;
  color: #1E2025;
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.font-15{
  background: transparent;
  color: #1E2025;
  font-family: "Public Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}
// @media only screen and (min-width: 1441px) {
//   .main-block-new {
//     padding: 0 6em 0 8.5em;
//   }
//   .left-panel {
//     max-width: 31.33em;
//   }
//   .top-sec-new {
//     max-width: 31.33em;
//   }
//   .field-sec {
//     max-width: 31.33em;
//   }
//   .field-details-wrapper {
//     max-height: 60em;
//   }
// }

// @media only screen and (max-width: 1200px) {
//   .main-block-new {
//     padding: 0 4em 0 4.5em;
//   }
//   .left-panel {
//     max-width: 20em;
//   }
//   .top-sec-new {
//     max-width: 20em;
//   }
//   .field-sec {
//     max-width: 20em;
//   }
//   .full-width-for-result{
//     max-width: 100%;
//   }
// }
