.heading-field{
    padding-top: 2.71vh;
    padding-bottom: 4.065vh;
    color: #000;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Public Sans";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.margin-top-1vh{
    margin-top: 1.084vh;
}
.skip-text{
    background: transparent;
    color: #000;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Public Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.skip-tag-wrapper{
    background: transparent;
    display: flex;
    flex-direction: column;
    gap: 2.71vh;
}
.skip-tag{
    width: fit-content;
    display: flex;
    padding: 1.355vh 0.651vw;
    align-items: flex-start;
    gap: 1.041vw;
    border-radius: 10px;
    background: #FFEAB6;
}
.collection-details-wrapper{
    display: flex;
    flex-direction: column;
    padding: 4.471vh 0px 4.471vh 1.302vw;
    height: 100%;
    border-radius: 15px;
    background: #FFF4D9;
    gap: 9.485vh;
}
.collection-name{
    color: #000;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Public Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.collection-box{
    background: transparent;
    display: flex;
    flex-direction: column;
    gap: 2.71vh;
}
.collection-box-details-wrapper{
    background: transparent;
    display: flex;
    flex-direction: column;
    gap: 2.710vh;
}
.text-header{
    background: transparent;
    color: #000;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Public Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 9.6354vw;
}
.data-text-details{
    background: transparent;
    color: #000;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Public Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    white-space: normal;
    overflow: visible;
    max-width: 100%;
    word-break: break-word;
    overflow-wrap: break-word;
}
.details-curved-vector{
    background: transparent;
}
.details-line-wrapper{
    background: transparent;
    position: relative;
}
.collection-box-wrapper{
    background: transparent;
    display: flex;
    flex-direction: column;
    gap: 10.84vh;
}
.details-line{
    left: 2.7vw;
    height: 100%;
    position: absolute;
    width: 1px;
    background-color: #212121;  
}
.line-vector-wrapper{
    background: transparent;
    display: flex;
    position: relative;
}
.collection-box-image-wrapper{
    background: transparent;
    display: flex;
    flex-direction: row;
    gap: 1.953125vw;
}
.pointer-wrapper{
    display: flex;
    flex-direction: row;
    gap: 0.7161vw;
    background: transparent;
    padding-left: 2.66927vw;
}
.small-image{
    background: transparent;
    width: 13.0859375vw;
    height: fit-content;
}
.text-box{
    background: transparent;
    gap: 1.953125vw;
    display: flex;
    flex-direction: row;
    align-items: center;
}