.signup-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.signup-container-left {
  display: flex;
  width: 50%;
  height: 100%;
  justify-content: center;
  align-items: center;
  .logo{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5.63vh;
    gap: 0.45vw;
    padding-bottom: 2.71vh;
  }
  .heading-conatiner{
    display: flex;
    flex-direction: column;
    gap: 2.16vh;
    align-items: center;
    justify-content: center;
    padding-bottom: 1.49vh;
    padding-top: 2.71vh;
  }
  .label-text{
    color:#000;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  .input-fields{
    display: flex;
    flex-direction: column;
    gap: 1.35vh;
  }
  .icon{
    height: 33.963px;
    width: 33.963px;
  }
  .or-text{
    color: #000;
    text-align: center;
    font-family: "Public Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }
  .form-container{
    display: flex;
    flex-direction: column;
    gap: 2.71vh;
    padding-top: 1.49vh;
  }
  .signup-fields{
    display: flex;
    flex-direction: column;
    gap: 2.71vh;
  }
  .signup-form {
    align-items: center;
  }
  .signup-button{
    width: 100%;
    background-color: gainsboro;
    color: grey;
  }
  .title {
    display: flex;
    align-items: center;
    font-size: 36px;
    font-weight: bold;
  }
  .non-user-forgot-pass{
    color: rgba(0, 0, 0, 0.40);
  text-align: center;

  /* Text/Regular/Link */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  }
  .box-input-signup {
    border-radius: 2px;
    border: 2px solid var(--Black, #000);
    background: #FEFBF4;
    box-shadow: 4px 0.26vw 0px 0px #000;
    width: 100%;
    height: 6.50vh;
  }
  
  .error {
    color: red;
  }
  .error-box{
    border-color: red;
    outline: none;
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .link {
    cursor: pointer;
    text-decoration: none;
    text-decoration-line: underline;
  }
  .webflow-button-container{
    cursor: pointer;
    padding: 1.72vh 8.36vw 1.79vh 8.36vw;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.97vw;
    border-radius: 60px;
    border: 2px solid #000;
    background: #345FF9;
    color: #FFF;
    text-align: center;
    font-family: "Public Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
  }
  .webflow-icon{
    background: #345FF9;
  }
}

.signup-container-right {
  width: 50%;
  height: 100%;
  background: #FCF4DC;
  align-items: center;
  display: flex;
  flex-direction: column;
  .content-container{
    padding-top: 18.29vh;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    align-items: center;
    gap: 8.80vh;
  }
  .right-image{
    background: transparent;
    height: 56.91vh;
    width: 30.66vw;
  }
  .speech-bubble-wrapper{
    display: flex;
    justify-content: center;
    background: transparent;
    position: relative;
    // height: calc(100% + 62px);
    width: fit-content;
    height: fit-content;
    max-width: 21.09375vw;
  }
  .speech-bubble{
    background: transparent;
    width: 100%;
    position: absolute;
    z-index: 0;
  }
  .speech-bubble-content-wrapper{
    height: fit-content;
    background: transparent;
    position: relative;
    z-index: 1;
    padding: 2.981vh 0.9114vw 8.67vh 1.2369vw;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .speech-bubble-content{
    background: transparent;
    color: #000;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  // .speech-bubble {
  //   max-width: 18.94vw;
  //   color: #000;
  //   font-family: "Public Sans";
  //   font-size: 16px;
  //   font-style: normal;
  //   font-weight: 400;
  //   line-height: normal;
  //   position: relative;
  //   align-items: center;
  //   display: flex;
  //   padding-left: 1.23vw;
  //   padding-right: 0.911vw;
  //   background: #FFECBF;
  //   font-family: Arial, sans-serif;
  //   font-size: 14px;
  //   border-radius: 5px;
  //   border: 2px solid #000;
  //   height: 10.97vh;
  //   box-shadow: 10px 10px 0 rgba(0,0,0,1); /* Sharper and darker shadow on the right side */
  //   overflow: visible; /* Make sure shadows and borders outside the box are visible */
  // }
  
  // /* Main triangular part of the arrow, adjusted to have a small curve at the tip */
  // .speech-bubble:after {
  //   content: '';
  //   position: absolute;
  //   bottom: -29.5px; /* Adjust the value to align with the bottom of the bubble */
  //   left: 50px; /* Adjust the value to position the tip of the arrow */
  //   width: 0;
  //   height: 0;
  //   border-style: solid;
  //   border-width: 30px 22px 0 22px;
  //   border-color: #FFECBF transparent transparent transparent;
  //   z-index: 2;
  // }
  
  // .speech-bubble:before {
  //   content: '';
  //   position: absolute;
  //   bottom: -38.5px; 
  //   left: 52px;
  //   width: 0;
  //   height: 0;
  //   border-style: solid;
  //   border-width: 32px 26px 0 25px;
  //   border-color: #000 transparent transparent transparent;
  //   z-index: 1;
  // }

}

.links-container {
  display: flex;
  justify-content: space-between;
}