.modal-container {
  width: 100%;
  max-width: 40.5598vw;
  display: flex;
  padding: 0px 3.125vw 7.47276vh 3.1901vw;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border: 2px solid #000;
  background: var(--Light-Yellow, #FFF1CF);
  box-shadow: 8px 8px 0px 0px #000;
}
.model-header{
  background: transparent;
  display: flex;
  padding: 3.2520vh 1.5625vw 0px 1.5625vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.168vh;
  align-self: stretch;
}
.modal-close {
  position: absolute;
  top: 1em;
  left: auto;
  right: 1em;
  cursor: pointer;
}
.bg-grey {
  background-color: rgba(0, 0, 0, 0.6) !important;
}
.modal-wrapper {
  background: transparent;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 4.065vh;
}
.phone-image{
  background: transparent;
}
.modal-title {
  background: transparent;
  color: var(--M3-sys-light-on-surface, #1D1B20);
  text-align: center;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 48px */
}
.option-box-img-block{
  background: transparent;
  border-radius: 10px;
  border: 1px solid #000;  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;
  height: 19.221vh;
  width: 15.1692vw;
}
.collection-type-text{
  background: transparent;
  color: #D65E00;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.1px;
}
.collection-text-wrapper{
  background: transparent;
  padding: 1.355vh 0.65vw;
}
.modal-btn-wrapper {
  background: transparent;
  display: flex;
  flex-direction: row;
  gap: 3.9062vw;
}
.modal-link-block {
  width: 100%;
  background: transparent;
  display: flex;
  flex-direction: column;
  gap: 3.252vh;
}


.modal-btn-title {
  color: #000;
  text-align: center;
  font-size: 2.2222222222em;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
}

.modal-btn-sub-title {
  color: #000;
  font-size: 1.3888888889em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}
