.secondary-custom-button{
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.35vh 1.56vw;
    border-radius: 100px;
    border: 1px solid var(--NEW-BLACK, #222);
    box-shadow: 0.54vh 0.26vw 0px 0px #000;
    color: var(--NEW-BLACK, #222);
    text-align: center;
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.1px;
    background: var(--BG-Yellow, #FEFBF4);
    cursor: pointer;
}
.secondary-custom-button:hover{
    box-shadow: 0px 0px 0px 0px #000;
}
.orange-color{
    background: var(--Orange, #FF9A03);
}