* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* font-family: 'Source Sans Pro', sans-serif; */
  background: #FEFBF4;
}
.App{
  height: 100%;
  width: 100%;
}
.error {
  color: red;
  font-size: 1.33vh;
  margin: 0;
  padding: 0;
}
.content{
  position: relative;
}
.breadcrumbs-container {
  margin-left:48.82vw;
  display: flex;
  position:absolute;
  justify-content: center;
}

.main-container {
  /* margin-top: 5.89vh;
  margin-left: 6.58vw;  */
  display: flex;
  height: 100%;
}
.content{
  width: 100%;
  height: 100%;
  padding-left: 1.302vw;
  padding-right: 1.302vw;
}

