.heading-container{
    display: flex;
    flex-direction: column;
    gap: 2.168vh;
    align-items: center;
    justify-content: center;
}
.message-alert{
    display: flex;
    flex-direction: column;
    gap: 2.71vh;
    align-items: stretch;
    justify-content: center;
}