.logs-box {
  border-radius: 0.9765625vw;
  background-color: #FFF4D9;
  padding: 4.47vh 5.53vw 3.65vh 1.30vw;
  display: flex;
  flex-direction: column;
  gap: 1.12vw;
  height: fit-content;
  width: 100%;
}
.square{
  margin-left: -5.65%;
  width: 0.976vw;
  height: 0.976vw;
  border-radius: 0.13020833333333334vw;
  background: #393939;
}
.logs-data-wrapper{
  display: flex;
  flex-direction: row;
  background: transparent;
  gap: 1.12vw;
}
.active-job-container{
  position: relative;
  align-items: center;
  justify-content: center;
  max-width: 26.041vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.9765625vw;
  background: var(--Fill-Yellow, #FFD888);
  padding: 7.1815vh 1.10677vw 3.387vh 1.10677vw;
  gap: 1.897vh;
}
.active-job-content-wrapper{
  display: flex;
  flex-direction: column;
  gap: 3.3875vh;
  background: transparent;
}
.active-job-tag-text{
  background: transparent;
  color: #000;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.active-job-tag-wrapper{
  left: auto;
  right: auto;
  top: -1.7615vh;
  border-radius: 1.5625vw;
  border: 1px solid #000;
  background: var(--Color-Neutral-white, #FFF);
  position: absolute;
  padding: 1.355vh 0.651vw;
}
.active-job-row-content{
  display: flex;
  flex-direction: row;
  gap: 1.627vw;
  background: transparent;
  justify-content: space-between;
}
.optimizing-total-images{
  color: #000;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Public Sans";
  font-size: 1.302vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: transparent;
}
.optimizing-total-images-count{
  color: #000;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Public Sans";
  font-size: 1.302vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: transparent;
}
.line {
  left: -1.05vw;
  width: 0.0651vw;
  background-color: #212121;  
  top: 1.6vh;
  position: relative;
  // left: -17.6px;
}
.logs-data-container{
  display: flex;
  flex-direction: row;
  background: transparent;
}
.left-box{
  max-width: 27.36vw;
  margin-right: 1.56vw;
  background: transparent;
  display: flex;
  gap: 5.42vh;
  flex-direction: column;
}
.heading-date{
  background: transparent;
  color: #000;
  font-family: "Public Sans";
  font-size: 1.5625vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.details-box{
  max-width: 25.8vw;
  width: 100%;
  background: transparent;
  padding: 2.03vh 0.84vw;
  border-radius: 0.6510vw;
  border: 1px solid #000;
  background: var(--Fill-Yellow, #FFD888);
  box-shadow: 4px 4px 0px 0px #000;
  gap: 1.62vh;
  display: flex;
  flex-direction: column;
}
.box-heading{
  background: transparent;
  display: flex;
  flex-direction: row;
  gap: 0.65vw;
}
.detail-key{
  color: #000;
  font-family: "Public Sans";
  font-size: 1.0416vw;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  background: transparent;
}
.detail-value{
  color: #000;
  font-family: "Public Sans";
  font-size: 1.0416vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: transparent;
}
.logs-table{
  display: flex;
  flex-direction: column;
  background: transparent;
  // gap: 5.826vh;
  gap: 50px;
}
.logs-heading{
  background: transparent;
  display: flex;
  flex-direction: row;
  padding-left: 1.56vw;
  gap: 1.30vw;
  color: #868484;
  font-family: "Public Sans";
  font-size: 1.0416vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.meta-items-wrapper{
  display: flex;
  flex-direction: column;
  background: transparent;
  gap: 5.013vh;
}
.logs-table-content-wrapper{
  gap: 2.439vh;
  display: flex;
  flex-direction: column;
  background: transparent;
}
// 1.56vw
.logs-table-data-wrapper{
  padding: 1.35vh 1.49vw 1.35vh 0px;
  display: flex;
  background: transparent;
  flex-direction: column;
  gap: 6.504vh;
}
.fixed-width-122px{
  width: 7.9427vw;
  background: transparent;
}
.details-link{
  background: transparent;
}
.logs-container{
  gap: 2.43vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.meta-item{
  background: transparent;
  display: flex;
  flex-direction: row;
  gap: 1.30vw;
  color: #000;
  font-family: "Public Sans";
  font-size: 1.0416vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 1.56vw;
}
.logs-heading-wrapper{
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.822vw;
}
.curve-vector{
  margin-left: -2.25vw;
  background: transparent;
  margin-top: -2.032vh;
  width: 2.246vw;
}
.right-box{
  background: transparent;
}
.collection-name{
  color: #000;
  font-family: "Public Sans";
  font-size: 1.5625vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-color: transparent;
}