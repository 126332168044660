#root {
  // background-color:#fffffe;
  height: 100vh;
}
.align-add-site-block{
  height: 80%;
  justify-content: center;
}
#modal-div {
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  z-index: 2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
} 
.position-absolute{
  position: absolute !important;
  z-index: 1;
  right: -80px;
  top: -30px;
}
.speech-bubble-wrapper{
  display: flex;
  justify-content: center;
  background: transparent;
  position: relative;
  // height: calc(100% + 62px);
  width: fit-content;
  height: auto;
  max-width: 21.09375vw;
}
.speech-bubble{
  background: transparent;
  width: 100%;
  position: absolute;
  z-index: 0;
}
.speech-bubble-content-wrapper{
  
  height: fit-content;
  background: transparent;
  position: relative;
  z-index: 1;
  padding: 1.761vh 0.6510vw 4.147vh 0.6510vw;
  align-items: center;
  justify-content: center;
  display: flex;
}
.site-data-wrapper{
  position: relative;
  display: flex;
  justify-content: end;
}
.speech-bubble-content-sitebox{
  background: transparent;
  color: var(--NEW-BLACK, #222);
  font-family: "Public Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.add-site-box{
  cursor: pointer;
  max-height: 38.21vh;
  max-width: 26.04vw;
  padding : 13.55vh 2.34vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 2px solid var(--NEW-BLACK, #222);
  background: var(--BG-Yellow, #FEFBF4);

  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
}
.add-site-image{
  height: 5.42vh;
  width: 2.60vw;
}
.add-site-box-ele{
  align-items: center;
  gap: 2.71vh;
  display: flex;
  flex-direction: column;
}
.add-site-text{
  color: var(--NEW-BLACK, #222);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.1px;
  width: 21.35vw;
}
.empty-site-page{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-container{
  display: flex;
  flex-direction: column;
  gap: 5.55vh;
  align-items: center;
}

.add-site-block{
  gap: 6.09vh;
  padding: 5.42vh 1.62vw;
  flex-direction: column;
  align-items: center;
  display: flex;
}
.box-design{
  border-radius: 9px;
  border: 2px solid #000;
  background: var(--Light-Yellow, #FFF1CF);
  box-shadow: 8px 8px 0px 0px #000;
}
.site-add-buttons{
  display: flex;
  gap: 0.65vw;
}
.site-box-wrapper{
  padding: 2.03vh 0.97vw;
}
.site-box-wrapper:hover{
  border-radius: 15px;
  background: var(--Orange, #FF9A03);
}
.sites-page-wrapper{
  display: flex;
  flex-wrap: wrap;
}
.block-sub-text{
  background: transparent;
  color: var(--NEW-BLACK, #222);
  text-align: center;
  font-family: "Public Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.text-content{
  background: transparent;
  gap: 4.06vh;
  display: flex;
  flex-direction: column;
}
.add-image{
  background: transparent;
}
.block-text{
  color: #000;
  text-align: center;
  font-family: "Public Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.1px;
  background: transparent;
  width: 33.91vw;
}
.step-image{
  width: 249.5px;
  height: 25.914px;
}