.loader-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
  
    .dot {
      width: 8px;
      height: 8px;
      background-color: #fff;
      border-radius: 50%;
      margin: 0 4px;
      animation: bounce 0.6s infinite alternate;
  
      &:nth-child(2) {
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        animation-delay: 0.4s;
      }
    }
  }
  
  @keyframes bounce {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-10px);
    }
  }
  