@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&family=Montserrat:wght@400;600&family=Outfit:wght@400;500&display=swap");
.font-one{
  font-size: 1vw;
}
.navbar-block {
  // height: 9.37vh;
  background-color: transparent;
  width: 100%;
  // padding: 3.34vh 3.25vw;
  padding: 2.71vh 0vw;
  max-width: 99.0694444444em;
  margin: auto;
  // font-size: 2.40vh;
  // border-bottom: 3px solid #0e172c;
}
.get-a-plan{
  cursor: pointer;
  background: transparent;
  color: var(--Orange, #FF9A03);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
  text-transform: uppercase;
}
.no-plan-wrapper{
  background: transparent;
  display: flex;
  flex-direction: column;
  gap: 1.7615vh;
  width: 100%;
  align-items: end;
  justify-content: center;
}
.navbar-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.grey{
  background-color: #F6F6F6;
}
.verify-license-btn {
  font-size: 14px;
  font-weight: 600;
  background-color: white;
  border: 1px solid #0e172c;
  padding: 7px 10px;
  border-radius: 5px;
  letter-spacing: 0.5px;
  text-decoration: none;
  color: #0e172c;
}
.data-main {
  max-width: 15.69vw;
  width: 100%;
  display: flex;
  padding: 2.03252vh 1.62760vw;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.355vh;
  border-radius: 10px;
  border: 1px solid var(--NEW-BLACK, #222);
  background: var(--Fill-Yellow, #FFD888);
}
.no-plan-width{
  max-width: 18.880vw;
}
.data-details-wrapper{
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.data-title {
  color: var(--NEW-BLACK, #222);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  background: transparent;
}
.go-back-icon{
  background: transparent;
  height: 7.4525vh;
  width: 3.5807vw;
  cursor: pointer;
}
.go-back-text{
  background: transparent;
  color: var(--NEW-BLACK, #222);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Public Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.goback-wrapper{
  background: transparent;
  display: flex;
  padding: 0px 0.651vw;
  justify-content: flex-end;
  align-items: center;
  gap: 1.627vw;
}
.nav-bg-color{
  background: #FFF4D9;
  border-radius: 7px;
}
.data-value{
  background: transparent;
  color: var(--NEW-BLACK, #222);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.data {
  // margin-top: 0.80vh;
  // color: #1F1F1F;
  font-feature-settings: 'clig' off, 'liga' off;
  // font-family: Poppins;
  // font-size: 2.94vh;
  // font-style: normal;
  // line-height: 2.94vh;
  display: flex;
  align-items: center;
  color: #FFF;
text-align: center;
font-family: "Inter" !important;
font-size: 1.25em;
font-style: normal;
font-weight: 700;
line-height: 1.2;
text-transform: uppercase;
}

.image-data {
  font-weight: 700;
}

.total-image {
  font-weight: 400;
}


.logo {
  color: black;
  text-decoration: none;

  .logo-text {
    // margin-top: 4.01vh;
    margin-left: 3.81vw;
    color: #333;
    font-family: "Poppins", sans-serif;
    font-size: 0.8vw;
    line-height: 1.3;
    font-weight: 900;
  }

  .pixie-text {
    font-size: 24px;
    font-family: 'Alegreya Sans SC', sans-serif;
  }

  .large-font {
    font-size: 24px;
  }

}

.nav-elements {
  display: flex;
  align-items: center;
  margin-top: 8px;

  .links {
    color: black;
    text-decoration: none;
    margin: 0 10px;
    letter-spacing: 1px;
    cursor: pointer;
  }

  .links:hover {
    text-decoration: underline;
    color: #face48;
  }
}

.navbar-link {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
}

.navbar-link-content {
  background-color: #FF9A03;
  float: left;
  width: 95%;
  padding: 15px 1% 14px 1%;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  color: #1d1d1d;
}

.navbar-link-content a {
  font-weight: 600;
  color: #1d1d1d;
}

.navbar-link-close {
  background-color: #FF9A03;
  float: left;
  padding: 15px 0px 14px 0px;
  font-size: 24px;
  width: 5%;
  top: 20%;
  right: 2%;
  text-align: end;
  padding-right: 2%;
  // font-family: noto sans;
  font-weight: 600;
  color: black;
  cursor: pointer;
}

.pixie-logo {
  width: 100%;
  max-width: 12.1527777778em;
flex-shrink: 0;
}
.nav-image {
  margin-right: 3.9vw;
  // width: 6vw;
  // height: 6vh;
}

.nav-text {
  padding-left: 2.67vh;
  width: 19.98vw;
  font-family: 'Poppins', sans-serif;
  color: rgba(0, 0, 0, 0.6);
  font-style: normal;
  font-weight: 400;
  font-size: 2.14vh;
  line-height: 3.21vh;
}
.main-modalForLinkmodal {
  width: 100%;
  float: left;
  // display: inline-flex;
  // justify-content: center;

}

.main__head {
  width: 100%;
  float: left;
}

.main__head__content {
  width: 88.5%;
  float: left;
  font-size: 24px;
  color: black;
  font-weight: 700;
  padding-top: 3.1%;
}

.main__content {
  width: 78.5%;
  float: left;
  font-size: 24px;
  color: black;
  font-weight: 400;
  text-align: left;
}

.main__content:after {
  content: "";
  display: inline-block;
  width: 100%;
}

.main__head__cancel {
  width: 11.5%;
  float: left;
  text-align: right;
}

.main__head__cancel img {
  width: 77.5%;
}

.ModalForLink {
  width: 72.7% !important;
  position: absolute;
  top: 20%;
  left: 14%;
  background-color: white;
  max-width: 90%;
  max-height: 90%;
  border-radius: 12px;
  box-shadow: 0px 0px 0px 36px #E3F6F5;
  padding-left: 7.55%;
  padding-bottom: 3.8%;
}

.main__content a {
  color: black;
}

@media only screen and (min-width: 520px) and (max-width: 960px) {

  .ModalForLink {
    width: 85% !important;
    top: 15%;
    left: 7.55%;
    padding-left: 5%;
    box-shadow: 0px 0px 0px 32px #E3F6F5;
  }

  .main__head__content {
    font-size: 21px;
  }
  
  .main__content {
    font-size: 21px;
    width: 88.5%;
  }

}

@media only screen and (max-width: 520px) {

  .ModalForLink {
    width: 80% !important;
    top: 15%;
    left: 10%;
    padding-left: 5%;
    box-shadow: 0px 0px 0px 28px #E3F6F5;
  }

  .main__head__content {
    font-size: 18px;
  }
  
  .main__content {
    font-size: 18px;
    width: 93.5%;
  }

  .main__head__cancel img {
    width: 97.5%;
  }
}