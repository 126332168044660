// .selector-class {
//     position: relative;
// }

.dropdown-container {
    position: relative;
}

.dropdown-header {
    cursor: pointer;
    width: fit-content;
    display: flex;
    padding: 5px 20px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 51px;
    border: 1px solid var(--NEW-BLACK, #222);
    background: var(--BG-Yellow, #FEFBF4);
    box-shadow: 4px 4px 0px 0px var(--NEW-BLACK, #222);
    color: #000;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.dropdown-arrow {
    width: 11px;
}

.dropdown-list {
    background-color: #FFF1CF;
    padding: 8px 7px;
    border-radius: 4px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    overflow-y: auto;
    position: fixed;
    z-index: 1000;
    scrollbar-width: none;
    -ms-overflow-style: none;
}
.dropdown-list::-webkit-scrollbar {
    display: none;
}
.dropdown-list-item {
    color: var(--NEW-BLACK, #222);
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    padding: 8px 12px;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 0.5px;
    background: transparent;
    cursor: pointer;
    // transition: background-color 0.2s;
}

.dropdown-list-item:hover {
    border-radius: 4px;
    border: 1px solid var(--NEW-BLACK, #222);
    background: var(--Color-Neutral-white, #FFF);
}
