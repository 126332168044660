.site-box {
  max-height: 38.21vh;
  max-width: 26.04vw;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
  }
  .reauthorize-content {
    position: relative;
    backdrop-filter: blur(2.9000000953674316px);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .reauthorize-content::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(30, 32, 37, 0.60);
    z-index: 1;
  }
  .reauthorize-content::after {
    content: 'Re-Authorize';
    position: absolute;
    color: var(--Color-Neutral-white, #FFF);
    text-align: center;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 150%;
    letter-spacing: 0.5px;
    z-index: 2;
  }
  
  .site-img-block {
    width: 100%;
    border: 1px solid var(--NEW-BLACK, #222);
    background-repeat: no-repeat;
    background-size: cover;
    flex-shrink: 0;
    border-radius: 0 0 10px 10px;
    height: 32.79vh;
  }
  .option-icon{
    background: transparent;
  }
  .site-info {
    display: flex;
    padding: 0.94vh 0.911vw;
    border-radius: 10px 10px 0px 0px;
    background: #1E2025;
    width: 100%;
    gap: auto;
  }
  .site-name{
    background: transparent;
    color: var(--Color-Neutral-white, #FFF);
    /* M3/body/large */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
    width: 24.86vw;
  }
}
.box-wrapper{
  border-radius: 10px;
  position: relative;
  display: flex;
  justify-content: end;
  align-items: baseline;
}
.options-box{
  right: -187px;
  top: 13px;
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.0840vh 0.45572vw;
  gap: 0.6775vh;
  border-radius: 4px;
  background: var(--Light-Yellow, #FFF1CF);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  z-index: 10;
}
.options{
  cursor: pointer;
  display: flex;
  width: 186px;
  padding: 8px 12px;
  align-items: center;
  align-self: stretch;
  background: transparent;
  color: var(--NEW-BLACK, #222);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.5px;
}
.options:hover{
  border-radius: 4px;
  border: 1px solid var(--NEW-BLACK, #222);
  background: #FFF;
}