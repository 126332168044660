.white-box-profile{
    border-radius: 1.606vh;
    background: #FFF;
    height: 61.57vh;
    padding: 4.016vh 1.95vw;

}
.profile-heading{
    color: #000;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Public Sans";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.speech-bubble-wrapper-profile{
    display: flex;
    justify-content: center;
    background: transparent;
    position: absolute;
    z-index: 2;
    left: -0.130vw;
    top: -7.723vh;
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
    max-width: 21.09375vw;
}
.speech-bubble-content{
    color: var(--NEW-BLACK, #222);
    font-family: "Public Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: transparent;
}
.edit-profile{
    height: 4.065vh;
    width: 1.953vw;
    cursor: pointer;
}
.profile-box-wrapper{
    display: flex;
    padding: 5.42vh 2.6041vw;
    flex-direction: column;
    gap: 8.26558vh;
    border-radius: 15px;
    background: #FFF4D9;
    max-width: 43.6848vw;
}
.profile-main{
    display: flex;
    flex-direction: column;
    gap: 2.71002vh;
}
.profile-container{
    display: flex;
    gap: 1.953125vw;
    padding: 2.71vh 0px 2.71vh 0px;
    align-items: center;
}
.actions-btn {
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.save-cancel{
    background: transparent;
    display: flex;
    gap: 2.47395vw;
}
.profile-custom-button{
    color: var(--NEW-BLACK, #222);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24.503px; /* 153.144% */
    letter-spacing: 0.585px;
}
.details-text{
    padding-left: 0.7057vw;
    color: rgba(34, 34, 34, 0.50);
    font-family: "Public Sans";
    font-size: 14.454px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21.681px */
    height: 5.907vh !important;
}
.delete-btn-wrapper{
    padding: 1.84552vh 2.6041vw;
}
.delete-btn-wrapper:hover{
    box-shadow: 0px 0px 0px 0px #000;
}
.delete-btn{
    background: transparent;
    color: var(--Light-Solid-Color-Extra-White, var(--Color-Neutral-white, #FFF));
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24.503px;
    letter-spacing: 0.585px;
}
.icon-delete-white{
    background: transparent;
}
.profile-buttons-wrapper{
    width: 100%;
    display: flex;
    background: transparent;
    align-items: center;
    justify-content: center;
}
.delete-btn-wrapper{
    display: flex;
    align-items: center;
    gap: 0.70898vw;
    border-radius: 42px;
    border: 2px solid #000;
    background: var(--NEW-BLACK, #222);
    box-shadow: 4px 4px 0px 0px #000;
    width: fit-content;
}

.edit-button{
    display: flex;
    padding: 1.606vh 0.781vw;
    align-items: center;
    gap:  0.39vw;
    border-radius: 4.016vh;
    background-color: #C5C6D0;
    color: #A2A0A0;
    font-family: Poppins;
    font-size: 0.911vw;
    font-style: normal;
    font-weight: 400;
    line-height: 1.874vh;
    cursor: pointer;
}
.box-input{
    border: 1px solid lightgray;
    border-radius: 0.669vh;
    padding: 1.338vh 0.651vw;
    width: 28.645vw;
    color: #2D334A;
    font-family: Poppins;
    font-size: 1.041vw;
    font-style: normal;
    font-weight: 400;
    opacity: 0.6;
    display: flex;
    padding: 1.874vh 1.5625vw;
    align-items: center;
    gap: 0.651vw;
    line-height: 2.677vh;
}
.form-profile{
    background: transparent;
    display: flex;
    flex-direction: column;
    gap: 4.065vh;
}
.details-container-name{
    display: flex;
    flex-direction: column;
    gap: 0.7384vh;
    background: transparent;
}
.details-container-email{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.7384vh;
    background: transparent;
}
.box-input-signup {
    border-radius: 2px;
    border: 2px solid var(--Black, #000);
    background: #FEFBF4;
    box-shadow: 4px 0.26vw 0px 0px #000;
    width: 100%;
    height: 6.50vh;
  }
.name-container{
    color: var(--Light-Typography-Color-Heading-Text, #4B465C);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Public Sans";
    font-size: 17.697px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: transparent;
}
.email-container{
    color: var(--Light-Typography-Color-Heading-Text, #4B465C);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Public Sans";
    font-size: 17.697px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: transparent;
}
.payment-details{
    align-items: center;
    display: flex;
    gap: 9.765vw;
}
.payment-container{
    color: #2D334A;
    font-family: Poppins;
    font-size: 1.041vw;
    font-style: normal;
    font-weight: 400;
    line-height: 2.67vh;
    opacity: 0.6;
}
.billing{
    color: #2D334A;
    font-family: Poppins;
    font-size: 1.041vw;
    font-style: normal;
    font-weight: 400;
    line-height: 2.677vh;
    cursor: pointer;
    text-decoration-line: underline;
    opacity: 0.6;
}
.edit-mode{
    color: var(--NEW-BLACK, #222);
}
.error-box{
    border-color: red;
    outline: none;
  }
  .error-text {
    color: red;
  }