/* DeleteAccountDialog.css */

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }
  .icon-image{
    width: 24px;
    height: 24px;
    background: transparent;
  }
  .dialog {
    padding: 3.252vh 1.5625vw 0px 1.5625vw;
    border-radius: 28px;
    border: 1px solid #000;
    background: var(--Light-Yellow, #FFF1CF);
    align-items: center;
    justify-content: center;
    max-width: 20.3125vw;
  }
  
  .message {
    font-size: 1.2em;
    margin: 20px 0;
  }
  
  .dialog-box-buttons {
    padding: 3.252vh 0.5208vw;
    display: flex;
    justify-content: center;
    gap: 0.5208vw;
    background: transparent;
  }
  .dialog-box-header-wrapper{
    display: flex;
    flex-direction: column;
    gap: 2.168vh;
    align-items: center;
    justify-content: center;
    background: transparent;
  }
  .dialog-box-message{
    color: var(--M3-black, var(--NEW-BLACK, #222));
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    background: transparent;
  }
  .confirmButton {
    border-radius: 100px;
    border: 1px solid #000;
    background: var(--Orange, #FF9A03);
    box-shadow: 4px 4px 0px 0px #000;
    display: flex;
    padding: 1.3550vh 1.7578125vw;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    display: flex;
    height: 5.420vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .confirmButton:hover{
    box-shadow: 0px 0px 0px 0px #000;
  }
  .cancelButton {
    border-radius: 100px;
    border: 1px solid #000;
    background: #FEFBF4;
    box-shadow: 4px 4px 0px 0px #000;
    display: flex;
    padding: 1.3550vh 1.7578125vw;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    display: flex;
    height: 5.420vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .cancelButton:hover{
    box-shadow: 0px 0px 0px 0px #000;
  }
  