.credits-details-section{
    display: flex;
    flex-direction: row;
    gap: 4.49vw;
    border-radius: 15px;
    background: #FFF4D9;
    padding: 4.74vh 1.30vw 7.45vh 4.10vw;
    width: fit-content;
}
.credits-left-section{
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.credits-points-wrapper{
    background: transparent;
}
.credits-right-section{
    background: transparent;
}
.credits-details-heading{
    padding-left: 1.98vw;
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Public Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: transparent;
}
.credit-details-wrapper{
    display: flex;
    flex-direction: column;
    gap: 2.71vh;
    background: transparent;
}
.credits-points-wrapper{
    color: #000;
    font-family: "Public Sans";
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.inner-box-image{
    height: 53px;
    mix-blend-mode: difference;
    border-radius: 7px;
}
.credit-details-text-wrapper{
    width: 14.58vw;
    background: transparent;
    color: #000;
    text-align: center;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}
.credit-details-text-rhs{
    background: transparent;
}
.credit-details-text-equal{
    background: transparent;
}
.credit-details-text-lhs{
    background: transparent;
    font-weight: 700;
}

.credit-details-box{
    display: inline-flex;
    padding: 2.03vh 0.97vw;
    flex-direction: column;
    align-items: center;
    gap: 2.71vh;
    border-radius: 15px;
    background: var(--Color-Neutral-white, #FFF);
}
.credit-details-points {
    list-style: none;
    gap: 2.43vh;
    display: flex;
    flex-direction: column;
    background: transparent;
  }
  .credit-details-points li{
    background: transparent;
  }
  .credits-box-section{
    background-color: transparent;
    display: flex;
    flex-direction: row;
    gap: 6.31vw;
    padding-top: 5.82vh;
  }
  .overage-subtext{
    background: transparent;
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Public Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .credit-details-points li:before {
    content: '\2B62';
    color: #000;
    font-size: 19px;
    padding-right: 1.04vw;
    background: transparent;
  }
  
.credits-box{
    display: inline-flex;
    padding: 6.09vh 1.62vw 2.71vh 1.62vw;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.62vw;
    border-radius: 20px;
    border: 1px solid #000;
    background: var(--Color-Neutral-white, #FFF);
    box-shadow: 0.81vh 0.39vw 0px 0px #000;
}
.credits-data{
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Public Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: transparent;
}