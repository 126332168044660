.navMenuContainer {
  padding: 2.71vh 1.30vw;
  height: 100%;
  width: 100%;
  background: #FEFBF4;
}
.logo-link{
  cursor: pointer;
  background: transparent;
}
.menuBox {
  border-radius: 7px;
  height: 100%;
  width: 100%;
  padding: 2.16vh 0.97vw;
  background-color: #FFF1CF;
}
.logo{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.63vh;
  gap: 0.45vw;
  background-color: transparent;
  .icon{
    // height: 4.471vh;
    width: 2.148vw;
    background-color: transparent;
  }
  .pixieText{
    background-color: transparent;
  }
}
.items-container{
  display: flex;
  flex-direction: column;
  background: transparent;
  gap: 2.03vh;
}
.padding-top-79px{
  padding-top: 10.70vh;
}
.items{
  display: flex;
  flex-direction: column;
  gap: 4.06vh;
  background-color: transparent;
  height: 100%;
}
.divider{
  height: 0.27vh;
  background-color: rgba(30, 32, 37, 0.50);
}
.menuItem {
  background: transparent;
}
.padding-top-20vh{
  padding-top: 20vh;
}
.menuItem.selected {
  display: flex;
  background: #222;
  border-radius: 2px;
  height: 4.47vh;
  align-items: center;
}

.menuLink{
  display: flex;
  gap: 0.97vw;
  background: transparent;
  height: 4.47vh;
  align-items: center;
  padding-left: 0.32vw;
}
.customIcon{
  background: transparent;
  width: 1.5625vw;
  height: 3.2520vh;
}
.icon-wrapper{
  background: transparent;
  padding: 0.67vh 0.32vw;
}
.cursor-poniter{
  cursor: pointer;
}
.logout-container{
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  background-color: transparent;
  padding-bottom: 4.33vh;
}
.text-field{
  background: transparent;
  color: #000;
  text-align: center;
  font-family: "Public Sans";
  font-size: 0.91145vw;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
}
.menuItem.selected .text-field {
  color: #FEFBF4;
}
.nav-menu {
  width: 22%;
  height: 100%;
  transition: width 0.5s;
  position: relative;
  z-index: 10;
}

.nav-menu.shrink {
  width: 8.1%;
}

.nav-menu.shrink .text-field {
  display: none;
}
.nav-menu.shrink .pixieText{
  display: none;
}
// .nav-menu.shrink .customIcon {
//   width: 100%;
//   height: auto;
// }

.menuItem, .menuLink {
  transition: all 0.5s;
}
