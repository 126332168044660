.main-block-pricing {
  width: 100%;
}

.for-yellowish-BG {
  position: absolute;
  bottom: 0;
  z-index: 0;
  width: 100%;
  float: left;
  /* height: 92.223vh; */
  background: radial-gradient(50% 100% at 50% 100%, #FFD803 0%, rgba(255, 216, 3, 0) 100%);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0px;
  padding: 0px;
  overflow-y: auto
}

.loader {
  border: .4vw solid rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  border-top: .4vw solid rgb(0, 0, 0);
  width: 2vw;
  height: 2vw;
  -webkit-animation: spin 1.1s linear infinite;
  /* Safari */
  animation: spin 1.1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.main-div {
  width: 100%;
  padding: 2.71vh 1.30vw; 
  gap: 10.84vh;
  display: flex;
  flex-direction: column;
}
.header-container{
  padding: 2.71vh 0vw;
  display: flex;
  flex-direction: row;
}
.main-header {
  color: #000;
  font-family: "Public Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100%;
}
.btn-wrapper{
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.main-semiHeader {
  width: 100%;
  margin-top: 1.48%;
  float: left;
}

.seminHeader-content {
  width: 42%;
  float: left;
  font-weight: 400;
  font-size: 1.12vw;
}

.seminHeader-btn {
  width: 58%;
  float: right;
}

.btn-div-design {
  display: flex;
  padding: 0.38vh 0.365vw;
  align-items: center;
  flex-shrink: 0;
  border-radius: 140.336px;
  border: 1.403px solid #000;
  background: #FFD597;
  box-shadow: 5.613px 5.613px 0px 0px #000;
  width: 22.62vh;
  justify-content: flex-end;
}

.btn-div-design-select {
  font-family: "Public Sans";
  line-height: 150%;
  width: 50%;
  border-radius: 33.681px;
  background: var(--Color-Neutral-white, #FFF);
  font-size: .99vw;
  padding: 0.7601vh 0.57096vw 0.686vh 0.7311vw;
  float: left;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  /* transition: .5s; */
}
.ReactModal__Overlay{
  z-index: 2;
}
.btn-div-design-deselect {
  cursor: pointer;
  width: 50%;
  border: none;
  color: #000;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Public Sans";
  font-size: 15.437px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  float: left;
  background: transparent;
  text-align: center;
  transition: .5s;
}

.btn-div-design-deselect:hover {
  color: #7b7b7b;
}

.plans-div {
  width: 100%;
}

.main-plan-div {
  align-items: center;
  width: 100%;
  /* width: 18.38vw; */
  padding-top: 2.032vh;
  padding-bottom: 2.032vh;
  padding-left: 2.01vw;
  padding-right: 2.01vw;
  border-radius: 20px;
  border: 1px solid #000;
  background: #FFFFFF;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  z-index: 2;
  position: relative;
}
.plans-details-container{
  margin-right: 1.4%;
  position: relative;
  margin-bottom: 2.8%;
}
.plan-image{
  background: transparent;
  height: 4.60vh;
}

.recommend {
  border-radius: 27px;
  border: 1px solid #000;
  background: #FD8;
  padding: 1.35vh 2.43vw;
  color: #000;
  text-align: center;
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  width: 100%;
  /* width: 18.38vw; */
  height: 15%;
  z-index: 1;
  position: absolute;
  top: -45px;
}
.plans-details-container{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.plan-name {
  width: 100%;
  color: #000;
  text-align: left;
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: transparent;
}

.plan-specification {
  width: 100%;
  color: rgba(0, 0, 0, 0.50);
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  background: transparent;
}
.plan-heading-container{
  background: transparent;
  max-width: 13.411vw;
  gap: 1.76vh;
  padding-top: 2.57vh;
  padding-bottom: 2.57vh;
  display: flex;
  flex-direction: column;
  align-items: start;
}
.plan-heading-container-top{
  background: transparent;
  gap: 1.76vh;
  padding-top: 2.57vh;
  padding-bottom: 2.57vh;
  display: flex;
  flex-direction: column;
  align-items: start;
}
.plan-payment {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: transparent;
  align-items: center;
}

.plan-amount {
  color: #000;
  font-family: "Public Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 900;
  line-height: 150%;
  background: transparent;
}

.plan-dollar {
  background: transparent;
}

.plan-amt-detail {
  background: transparent;
}

.plan-upperPT {
  color: rgba(0, 0, 0, 0.50);
  font-family: "Public Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  background: transparent;
}
.price-text{
  background: transparent;
}
.per-text{
  background: transparent;
}
.plan-lowerPT {
  float: left;
  font-size: .7vw;
  font-weight: 400;
}

.get-plan-button {
  cursor: pointer;
  display: inline-flex;
  padding: 10px 25px;
  justify-content: center;
  align-items: center;
  border-radius: 38px;
  border: 2px solid #000;
  background: #FFAB69;
  box-shadow: 4px 4px 0px 0px #000;
  color: #000;
  text-align: center;
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin-top: 3.65vh;
  margin-bottom: 4.06vh;
}

.get-plan-button:hover {
  box-shadow: 0px 0px 0px 0px #000;
}

.get-plan-button-click {
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 8px;
  background-color: black;
  float: left;
  padding-top: 2.9%;
  padding-bottom: 2.9%;
  text-align: center;
  font-weight: 700;
  font-size: 1.12vw;
  margin-top: 9.25%;
  transition: .2s;
  color: black;
  background-color: white;
  border: 2px solid black;
}

.get-plan-button-sub {
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
  background-color: white;
  border: 2px solid black;
  float: left;
  color: #2A2216;
  ;
  padding-top: 4.7%;
  padding-bottom: 4.7%;
  text-align: center;
  font-weight: 700;
  font-size: 1.12vw;
  margin-top: 9.25%;
  transition: .4s;
  box-shadow: 0px 4px 0px 0px #1F1F1F;
}

.get-plan-button-sub:hover {
  /* color: white; */
  background-color: #1F1F1F;
  border: 2px solid black;
  box-shadow: 0px 4px 0px 0px white;
}

.get-plan-button-sub-click {
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
  background-color: white;
  border: 2px solid black;
  float: left;
  color: #2A2216;
  ;
  padding-top: 2.9%;
  padding-bottom: 2.9%;
  text-align: center;
  font-weight: 700;
  font-size: 1.12vw;
  margin-top: 9.25%;
  transition: .4s;
  box-shadow: 0px 4px 0px 0px #1F1F1F;
  display: flex;
  justify-content: center;
}

.get-plan-button-sub-not {
  width: 100%;
  border-radius: 8px;
  background-color: #FFD803;
  border: 2px solid black;
  float: left;
  color: #2A2216;
  cursor: pointer;
  padding-top: 3.86%;
  padding-bottom: 3.86%;
  text-align: center;
  font-weight: 700;
  font-size: 1.12vw;
  margin-top: 9.25%;
  transition: .4s;
  box-shadow: 0px 4px 0px 0px #1F1F1F;
  display: flex;
  justify-content: end;
}

.get-plan-button-sub-not-click {
  width: 100%;
  border-radius: 8px;
  background-color: #FFD803;
  border: 2px solid black;
  float: left;
  color: #2A2216;
  cursor: pointer;
  padding-top: 2.9%;
  padding-bottom: 2.9%;
  text-align: center;
  font-weight: 700;
  font-size: 1.12vw;
  margin-top: 9.25%;
  transition: .4s;
  box-shadow: 0px 4px 0px 0px #1F1F1F;
  display: flex;
  justify-content: center;
}

.currentPlanBtn {
  float: left
}

.currentPlan {
  display: flex;
  flex-direction: row;
  padding: 5.69vh 3.64vw;
  border-radius: 15px;
  background: #FFF4D9;
  justify-content: center;
  align-items: center;
}

.settingIcon {
  float: left;
  width: 11%;
  margin-right: 2%;
}

.plan-detail {
  max-width: 13.411vw;
  width: 100%;
  padding-top: 4.06vh;
  background: transparent;
  display: flex;
  flex-direction: column;
  gap: 2.30vh;
}

.detail-header {
  width: 100%;
  color: #000;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  background: transparent;
}

.detail-content {
  width: 100%;
  background: transparent;
  gap: 1.35vh;
  color: rgba(0, 0, 0, 0.50);
  font-family: "Public Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  display: flex;
  flex-direction: column;
}

.detailed-content {
  width: 100%;
  background: transparent;
  gap: 0.65vw;
  display: flex;
}

.tick-img{
  background: transparent;
}


.main-content {
  width: 100%;
  gap: 10.84vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.delete-btn-img{
  background: transparent;
}
.plan-delete-btn{
  display: flex;
  flex-direction: row;
  gap: 0.65vw;
  align-items: center;
  justify-content: center;
  width: 17.70vw;

}
.details-box{
  background: transparent;
  padding: 2.03vh 0.84vw;
  border-radius: 10px;
  border: 1px solid #000;
  background: var(--Fill-Yellow, #FFD888);
  box-shadow: 4px 4px 0px 0px #000;
  gap: 1.62vh;
  display: flex;
  flex-direction: column;
}
.current-plan-right{
  background: transparent;
}
.plan-date-details{
  background: transparent;
  display: flex;
  flex-direction: row;
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.credits-left{
  color: #000;
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  background: transparent;
  padding-bottom: 4.74vh;
}
.plan-date-heading{
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  background-color: transparent;
}
.box-details{
  display: flex;
  flex-direction: column;
  align-items: start;
  background: transparent;
}

.current-plan {
  border-radius: 15px;
  background: #FFF4D9;
  display: flex;
  padding: 5.69vh 3.64vw;
  gap: 2.60vw;
  width: fit-content;
}
.current-plan-left{
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.box-upper-wrapper{
  background: transparent;
  display: flex;
  flex-direction: row;
}
.plan-settings-img{
  background-color: transparent;
}
.plan-settings{
  background-color: transparent;
  border-radius: 2.5px;
  border: 1.25px solid #000;
  background: #FFD597;
  box-shadow: 3.75px 3.75px 0px 0px #000;
  padding: 0.40vh 0.19vw;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.plan-settings:hover{
  box-shadow: 0px 0px 0px 0px #000;
}
.active-tag{
  border-radius: 4px;
  border: 1px solid #000;
  background: #FD8;
  display: inline-flex;
  padding: 1.355vh 0.6510vw;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #000;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -3vh;
  left: auto;
  right: auto;
}
.plan-details-left-box{
  position: relative;
  max-width: 28.125vw;
  background: transparent;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #000;
  background: var(--Color-Neutral-white, #FFF);
  padding-top: 2.03vh;
  padding-left: 3.45vw;
  padding-bottom: 3.65vh;
  padding-right: 1.49vw;
  display: flex;
  flex-direction: column;
  gap: 3.25vh;
}
.all-plan {
  width: 100%;
  float: left;
  /* display: inline-flex; */
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
}

.recommend-CP {
  float: left;
  background-color: rgba(255, 216, 3, 1);
  border: 1px solid black;
  font-size: .8vw;
  border-radius: 4px;
  padding-top: .5%;
  padding-bottom: .5%;
  padding-left: 2.2%;
  padding-right: 2.2%;
  position: absolute;
  top: -18.1%;
  font-weight: 600;
}

.active-green {
  color: #00b100;
  text-transform: capitalize;
}

.active-red {
  color: rgb(229, 0, 0);
  text-transform: capitalize;
}

.settingIcon-CP {
  position: absolute;
  top: 9%;
  right: 1%;
  background-color: rgba(255, 216, 3, 1);
  width: 2.2vw;
  height: 2.2vw;
  padding: .5%;
  border-radius: 6px;
  border: 1px solid black;
  cursor: pointer;
  z-index: 100;
}

.right5 {
  right: 5%;
  display: flex;
  justify-content: center;
}
.right6 {
  right: 1%;
  display: flex;
  justify-content: center;
}

.right55 {
  width: 88%;
  display: flex;
  justify-content: center;
  /* padding-left: 15%; */
}

.settingIcon-CP-img {
  width: 100%
}

.settingIcon-CP-img-click {
  animation: spinSetting 1.9s linear infinite;
}
.survey-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050; /* Ensure it's above other content */
}

.survey-modal {
  width: 90%;
  max-width: 400px; /* Adjusted for closer width to the image */
  background-color: white;
  border-radius: 12px; /* Slightly increased border radius */
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add some shadow for depth */
}

.survey-header {
  background-color: #f0f0f0; /* Adjusted color to match the image */
  padding: 20px;
  color: black;
  font-size: 18px;
  position: relative;
  font-weight: 500; /* Slightly bolder font */
}

.survey-close {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
  color: #a9a9a9; /* Lighter color for the close button */
}

.survey-body {
  padding: 20px;
  font-family: Arial, sans-serif; /* Adjusted font for better readability */
}

.survey-option {
  display: block;
  margin-bottom: 15px; /* More space between options */
  font-size: 16px;
  cursor: pointer;
}

.survey-option input[type="radio"] {
  appearance: none; /* Hide the default radio button */
  background-color: #fff; /* Background color */
  margin-right: 10px;
  cursor: pointer;
  width: 20px; /* Custom size */
  height: 20px; /* Custom size */
  border-radius: 50%; /* Make it round */
  border: 2px solid #000; /* Black border */
  position: relative;
  top: 4px; /* Adjust to center vertically with the text */
}

.survey-option input[type="radio"]:checked::after {
  content: ""; /* Needed to create the inner circle */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px; /* Size of the inner circle */
  height: 10px;
  background-color: #000; /* Black inner circle */
  border-radius: 50%;
  display: block;
}

.survey-option label {
  cursor: pointer; /* Ensure the label changes cursor as well */
}

.survey-footer {
  padding: 20px; /* More padding for footer */
  text-align: center; /* Center align the submit button */
}

.survey-submit {
  padding: 12px 25px;
  background-color: #000000; /* Changed to black */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.survey-submit:hover {
  background-color: #333333; /* Dark grey for hover effect */
}



@keyframes spinSetting {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (min-width: 520px) and (max-width: 960px) {

  .main-div {
    padding-left: 9.362%;
    padding-right: 9.09%;
    float: left;
    /* background: radial-gradient(50% 100% at 50% 100%, #FFD803 0%, rgba(255, 216, 3, 0) 100%); */
    /* background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat; */
  }
  .right5 {
    right: 6%;
  }
  .right6 {
    right: 1%;
    display: flex;
    justify-content: center;
  }
  .loader {
    border: .7vw solid rgba(0, 0, 0, 0.6);
    border-top: .7vw solid rgb(0, 0, 0);
    width: 3vw;
    height: 3vw;
  }

  .main-header {
    font-size: 3.2vw;
  }

  .main-semiHeader {
    margin-top: 1.8%;
  }

  .seminHeader-content {
    width: 60%;
    font-size: 2.1vw;
  }

  .seminHeader-btn {
    width: 40%;
    float: right;
  }

  .btn-div-design {
    width: 65.8%;
    padding: 3px;
  }

  .btn-div-design-select {
    font-size: 1.99vw;
  }

  .btn-div-design-deselect {
    font-size: 1.99vw;
  }

  .main-plan-div {
    width: 29.9%;
    margin-right: 2.8%;
    margin-bottom: 2.8%;
  }

  .recommend {
    font-size: 1.5vw;
  }

  .plan-name {
    font-size: 1.8vw;
    margin-top: 3%;
  }

  .plan-specification {
    font-size: 1.45vw;
    padding-top: 4%;
  }

  .plan-amount {
    font-size: 4vw;
    line-height: 4vw;
  }

  .plan-dollar {
    font-size: 2.1vw;
    line-height: 3.1vw;
  }

  .plan-upperPT {
    font-size: 1.3vw;
  }

  .plan-lowerPT {
    font-size: 1.3vw;
  }

  .get-plan-button {
    border-radius: 6px;
    font-size: 1.8vw;
  }

  .get-plan-button-sub-not {
    border-radius: 6px;
    font-size: 1.8vw;
  }

  .get-plan-button-sub {
    border-radius: 6px;
    font-size: 1.8vw;
  }

  .detail-header {
    font-size: 1.5vw;
  }

  .detailed-content {
    font-size: 1.3vw;
    margin-top: 2%;
  }

  .detailed-content img {
    width: 3.26%;
    margin-bottom: 1%;
    margin-right: 2%;
  }

  .current-plan {
    padding: 2.5%;
    border-radius: 12px;
    font-size: 2vw;
  }

  .recommend-CP {
    font-size: 1.5vw;
  }

  .settingIcon-CP {
    width: 3vw;
    height: 3vw;
  }

}

@media only screen and (max-width: 520px) {

  .main-block-pricing {
    width: 100%;
  }

  .loader {
    border: 1.2vw solid rgba(0, 0, 0, 0.6);
    border-top: 1.2vw solid rgb(0, 0, 0);
    width: 6vw;
    height: 6vw;
  }


  .main-header {
    font-size: 5vw;
  }

  .seminHeader-content {
    width: 100%;
    float: left;
    font-size: 3.12vw;
  }

  .seminHeader-btn {
    width: 100%;
    float: left;
    display: flex;
    justify-content: center;
    padding-top: 5%;
    z-index: 19000000;
  }

  .btn-div-design {
    width: 45.8%;
    border-radius: 6px;
    padding: 3px;
  }

  .btn-div-design-select {
    border-radius: 6px;
    font-size: 3.5vw;
  }

  .btn-div-design-deselect {
    border-radius: 6px;
    font-size: 3.5vw;
  }

  .plans-div {
    width: 100%;
    float: left;
    margin: auto;
  }

  .main-plan-div {
    width: 100%;
    padding-top: 8%;
    padding-bottom: 5%;
    padding-left: 7%;
    padding-right: 7%;
    border-radius: 15px;
    margin-bottom: 7%;
  }

  .recommend {
    font-size: 3vw;
  }

  .plan-name {
    width: 100%;
    font-size: 4vw;
    letter-spacing: 0.15em;
  }

  .plan-specification {
    font-size: 2.7vw;
  }

  .plan-amount {
    font-size: 7vw;
    line-height: 7vw;
  }

  .plan-dollar {
    font-size: 3.5vw;
    line-height: 6vw;
  }

  .plan-upperPT {
    font-size: 2.5vw;
  }

  .plan-lowerPT {
    font-size: 2.5vw;
  }

  .get-plan-button {
    font-size: 3.8vw;
  }

  .get-plan-button-sub-not {
    font-size: 3.8vw;
  }

  .get-plan-button-sub {
    font-size: 3.8vw;
  }

  .detail-header {
    font-size: 3vw;
  }

  .detailed-content {
    font-size: 2.4vw;
    margin-top: 2.5%;
  }

  .detailed-content img {
    width: 2.86%;
  }

  .current-plan {
    padding: 4.5%;
    padding-top: 9%;
    border-radius: 12px;
    font-size: 4vw;
    margin-bottom: 12%;
    margin-top: 9%;
  }

  .recommend-CP {
    font-size: 3vw;
    top: -10%
  }

  .settingIcon-CP {
    width: 6vw;
    height: 6vw;
    padding: 1%;
    top: 5%;
    right: 2.5%;
  }

  .right5 {
    right: 16%;
    display: flex;
    justify-content: center;
  }

  .right6 {
    right: 2%;
    display: flex;
    justify-content: center;
  }

  .right55 {
    width: 68%;
    display: flex;
    justify-content: center;
    /* padding-left: 15%; */
  }
}
