.collections-data-top {
    display: flex;
    flex-direction: column;
    padding: 4.93vh 2.47vw 4.93vh 2.86vw;
    border-radius: 15px;
    background: #FFF4D9;
}
.collections-data{
    gap: 9.48vh;
    display: flex;
    flex-direction: column;
    background: transparent;
}
.top-padding{
    padding-top: 2.43vh;
}
.makeStyles-root-5{
    background: transparent;
}
.bottom-padding{
    padding-bottom: 2.43vh;
}
.no-collection{
    background: transparent;
    font-family: Inter;
    color: #000;
    font-size: 16px;
}
.no-collection a{
    background: transparent;
}
.fields{
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #000;
    background: var(--Color-Neutral-white, #FFF);
    display: flex;
    flex-direction: column;
    align-items: start;
    align-self: stretch;
    box-shadow: 5px 5px 0px 0px #000;
    gap: 2.43vh;
}
.collection-images-count{
    background-color: transparent;
}
.collection-images{
    background: transparent;
    color: #656565;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 2.99vw;
}
.collection-data{
    background: transparent;
    display: flex;
    flex-direction: column;
    gap: 3.79vh;
}
.check-box-btn{
    background: transparent;
}
.MuiTouchRipple-root{
    background-color: transparent;
}
.MuiIconButton-label{
    background: transparent;
}
.MuiTypography-root{
    background: transparent;
}
.collection-name-txt{
    color: #000;
    font-family: Inter;
    font-size: 1.8229vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 0.727vw;
    background: transparent;
}
.PrivateSwitchBase-root-9{
    padding: 0 !important;
    padding-top: 0.67750vh !important;
    padding-bottom: 0.67750vh !important;
}
.MuiIconButton-root{
    width: 2.34375vw !important;
    height: 4.2682vh !important;
    padding: 0 !important;
    padding-top: 0.6775vh !important;
    padding-bottom: 0.6775vh !important;
}
.PrivateSwitchBase-root-17{
    padding: 0 !important;
    padding-top: 0.67750vh !important;
    padding-bottom: 0.67750vh !important;
}
.circular-loader{
    display: flex;
    width: 100%;
    justify-content: center;
    background: transparent;
}
.makeStyles-root-1{
    background: transparent;
}
.MuiCircularProgress-svg{
    background: transparent;
}
.PrivateSwitchBase-root-4{
    width: 2.34375vw !important;
    height: 4.2682vh !important;
    padding: 0 !important;
    padding-top: 0.6775vh !important;
    padding-bottom: 0.6775vh !important;
}
.MuiButtonBase-root{
    justify-content: start !important;
}
.PrivateSwitchBase-root-7{
    width: 2.34375vw !important;
    height: 4.2682vh !important;
    padding: 0 !important;
    padding-top: 0.6775vh !important;
    padding-bottom: 0.6775vh !important;
}
.collection-name-row{
    background: transparent;
}
.collection-header{
    display: flex;
    flex-direction: column;
    gap: 0.47425vh;
    background-color: transparent;
}
.field-separator {
    height: 1px;
    width: 100%;
    background-color: #000;
}
.multi{
    background-color: transparent;
}
.field-right-wrapper{
    display: flex;
    flex-direction: row;
    background: transparent;
    align-items: center;
    gap: 2.604vw;
}
.field-name-txt{
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 1.6276vw;
    background: transparent;
    width: 16.08vw;
    background: transparent;
    width: 16.08vw;
}
.single-collection{
    background-color: transparent;
    padding-left: 1.75vw;
    padding-right: 4.23vw;
    width: 100%;
    // 2.43vh
    // 2.43vh
}
.type-icon{
    background: transparent;

}
.PrivateSwitchBase-root-25{
    padding: 0px;
    padding-top: 0.6775vh !important;
    padding-bottom: 0.6775vh !important;
}
// .selector-class {t
//     border-radius: 51px;
//     border: 1px solid var(--NEW-BLACK, #222);
//     background: var(--BG-Yellow, #FEFBF4);
//     box-shadow: 4px 4px 0px 0px var(--NEW-BLACK, #222);
//     display: flex;
//     padding: 0.67vh 1.30vw;
//     flex-direction: column;
//     align-items: center;
//     width: fit-content;
//     position: relative; /* Ensure the dropdown positions correctly */
//   }
  
.selector-class{
    width: fit-content;
    background: transparent;
    position: relative;
}
.selector-class select {
    appearance: none;
    background: var(--BG-Yellow, #FEFBF4);
    display: flex;
    align-items: center;
    padding: 0.67750vh 1.302vw;
    border-radius: 51px;
    border: 1px solid var(--NEW-BLACK, #222);
    box-shadow: 4px 4px 0px 0px var(--NEW-BLACK, #222);
    width: fit-content;
    color: #000;
    font-family: Inter;
    font-size: 24px;
    position: relative;
}

.dropdown-arrow-icon {
    width: 11px;
    top: 50%;
    position: absolute;
    right: 10px;
    pointer-events: none;
    background: transparent;
}
  .selector-class select:focus {
    outline: none;
    // box-shadow: 2px 2px 0px 0px var(--NEW-BLACK, #222);
  }
//   .options-wrapper{
//     background-color: #FFF1CF;
//     border-radius: 4px;
//     margin: 2px 0;
//     box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
//   }
  .selector-class select option {
    color: #222;
    padding: 1.355vh 0.651vw;
    font-size: 13px;
    font-weight: 400;
    font-family: Roboto, sans-serif;
    line-height: 1.2;
    letter-spacing: 0.5px;
    border: none;
}

.selector-class select option:hover, 
.selector-class select option:focus {
    border-radius: 4px;
    border: 1px solid var(--NEW-BLACK, #222);
    background: var(--Color-Neutral-white, #FFF);
}
  .each-field{
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  
  
.field-name{
    justify-content: center;
    display: flex;
    border-radius: 3px;
    padding: 0.94vh 0.39vw;
    background: var(--Fill-Yellow, #FFD888);
    width: fit-content;
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.check-box-btn{
    background: transparent;
}