.search-div {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .search-bar {
    width: 37.239vw;
    height: 9.7560vh;
    flex-shrink: 0;
    border-radius: 28px 28px 0px 0px;
    background: #FFF1CF;
    padding: 0 2.27vw;
    border: none;
    color: #222;
    font-feature-settings: 'salt' on;
    font-family: Poppins;
    font-size: 2.14vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-image: url('../../images/search 1.svg');
    background-repeat: no-repeat;
    text-indent: 1.3vw;
    background-position: 1.3vw center;
    background-size: 3vh 3vw;
    padding-right: 3.5vw; // space for the clear icon
  }
  
  .search-bar:focus {
    outline: none;
  }
  
  .clear-icon {
    position: absolute;
    right: 1vw;
    top: 50%;
    transform: translateY(-50%);
    width: 1.5625vw;
    height: 3.252vh;
    background-image: url('../../images/close_24px.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-color: transparent;
    cursor: pointer;
  }
  