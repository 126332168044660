.main-block{
  display: flex;
  flex-direction: column;
  gap: 4.06vh;
  padding: 2.71vh 1.30vw;
}
.site-details-section{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.left-site-details-section{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  position: relative;
}
.site-name{
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Public Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.sub-heading-section{
  display: flex;
  flex-direction: column;
  gap: 1.08vh;
}
.head-lite{
  color: rgba(0, 0, 0, 0.80);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}
.select-all{
  color: #000;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-decoration-line: underline;
  cursor: pointer;
}
.selected-images{
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Public Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.right-site-details-section{
  display: flex;
  flex-direction: column;
  gap: 4.74vh;
}
.right-down-site-section{
  display: flex;
  flex-direction: column;
  gap: 0.58vw;
  align-items: center;
}
.images-count{
  font-weight: 700;
}
.dropdown-item{
  cursor: pointer;
  width: 100%;
  color: var(--NEW-BLACK, #222);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  padding: 1.0840vh 0.78125vw;
  background: transparent;
}
.no-plan-optimization-wrapper{
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6.775vh;
  padding: 6.775vh 3.2552vw;
  border-radius: 9px;
  border: 2px solid #000;
  background: var(--Light-Yellow, #FFF1CF);
  box-shadow: 8px 8px 0px 0px #000;
  align-items: center;
  justify-content: center;
}
.no-plan-top-wrapper{
  display: flex;
  flex-direction: column;
  gap: 1.355vh;
  background: transparent;
}
.dialog-box-message-heading{
  color: #000;
  text-align: center;
  font-family: "Public Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.1px;
  background: transparent;
}
.dialog-box-message-text{
  color: #000;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  max-width: 23.632vw;
  background: transparent;
}
.plans-button{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.355vh 1.62760vw;
  border-radius: 38px;
  border: 2px solid #000;
  background: #FFAB69;
  box-shadow: 4px 4px 0px 0px #000;
  color: #000;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 30px */
  cursor: pointer;
}
.dialog-box-pricing-cross{
  cursor: pointer;
  right: 1.302vw;
  top: 2.71vh;
  background: transparent;
  position: absolute;
  width: 4.607vh;
  height: 2.2135vw;
}
.plans-button:hover{
  box-shadow: 0px 0px 0px 0px #000;
}
.makeStyles-root-5{
  background: transparent;
}
.dropdown-content a {
  width: 100%;
  background: transparent;
}
.dropdown-item:hover{
  border-radius: 4px;
  border: 1px solid var(--NEW-BLACK, #222);
  background: var(--Color-Neutral-white, #FFF);
}
.dropdown-content{
  top: 7.1815vh;
  gap: 0.6775vh;
  position: absolute;
  z-index: 2;
  display: flex;
  padding: 1.084vh 0.4557vw;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  border-radius: 4px;
  background: var(--Light-Yellow, #FFF1CF);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
}
.optimize-now{
  background: #FFAB69;
  color: #000;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}
.sites-dropdown{
  border-radius: 4px;
  border: 1px solid #000;
  background: #FFD597;
  display: flex;
  padding: 1.35vh 0.455vw;
  align-items: center;
  justify-content: center;
  height: fit-content;
  cursor: pointer;
}
.sites-dropdown-arrow{
  background: transparent;
}
.sites-upper-container{
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.97vw;
}
.info-text{
  color: rgba(0, 0, 0, 0.50);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.collections-scroll-container{
  width: 100%;
  margin-top: 3.21vh;
  flex: 1;
  max-height: calc(100vh - 26.77vh);
  overflow-y: auto;
}
.collections-scroll-container::-webkit-scrollbar {
  width: 0.65vw;
}

.collections-scroll-container::-webkit-scrollbar-thumb {
  background-color: #1A1A1A;
  border-radius: 2.67vh;
}

.collections-scroll-container::-webkit-scrollbar-track {
  border-radius: 2.67vh;
  background-color: #ECECEC;
}