.custom-button {
  min-width: 9.244vw;
  width: 100%;
  padding: 1.62vh 1.56vw;
  color: var(--White, var(--Color-Neutral-white, #FFF));
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;
  border-radius: 60px;
  border: 1px solid #000;
  background: #000;

  &.black-white {
    background-color: #FFD803;
    color: #0e172c;
    border: 1px solid #FFD803;
    font-weight: bold;
  }

  &.mr {
    margin-right: 10px;
  }

  &.ml {
    margin-left: 10px;
  }

  &:disabled {
    cursor: not-allowed;
  }
}
