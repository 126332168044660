.main-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.api-key-input{
  display: flex;
  padding: 4px 0px 4px 16px;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 4px 4px 0px 0px;
  width: 100%;
}
.api-key-input {
  width: 100%;
}

.api-key-label {
  position: absolute;
  color: var(--M3-sys-light-primary, var(--Orange, #FF9A03));
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-left: 0.32vw;
  margin-top: -1.08vh;
  // left: 10px;
  // top: -2px;
}

.api-key-textbox {
  width: 100%;
  display: flex;
  padding: 4px 0px 4px 16px;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 4px 4px 4px 4px;
  height: 6.50vh;
  border: 2px solid #FF9A03;
}
.width-817px{
  width: 53.19vw;
}
.input-container{
  width: 100%;
}
.api-key-sub-text{
  color: var(--Orange, #FF9A03);
  font-family: "Public Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  padding-left: 2.08vw;
}
.api-key-steps-link{
  color: var(--Orange, #FF9A03);
  font-family: "Public Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 133.333% */
  text-decoration-line: underline;
}

.add-api-block {
  gap: 0.65vw;
  display: flex;
  width: 100%;
}
.add-site-block{
  width: 100%;
}
// .closeBtn {
//   position: absolute;
//   right: 80px;
//   top: 20px;
//   cursor: pointer;
// }

// .add-api-block {
//   display: flex;
//   height: 48px;
//   min-width: 50vw;
//   margin: 20px 0;

//   input {
//     width: 600px;
//     padding: 3px 5px;
//     border: 2px solid #0e172c;
//     font-size: 18px;
//   }

//   button {
//     padding: 3px 5px;
//     font-size: 18px;
//     background-color: #0e172c;
//     height: 100%;
//     border: none;
//     color: #fffffe;
//     cursor: pointer;
//     width: 120px;

//     &:disabled {
//       cursor: not-allowed;
//       color: whitesmoke;
//     }
//   }
// }

// .more-info-block {
//   margin-top: 50px;
//   display: flex;
//   justify-items: start;
//   flex-direction: column;
// }

// .bold {
//   font-size: 18px;
//   line-height: 130%;
//   font-weight: 300;
// }

// .img-mw-800 {
//   width: 100%;
//   max-width: 930px;
// }

// .help-image-wrapper {
//   margin-top: 20px;
// }